<template>
    <section class="development">
        <div class="navigation d-flex mt-3">
            <span class="d-inline-block mx-2">
                <img src="../../assets/views/development/arrow-back.svg" alt="">
                <router-link
                    :to="{ name: 'Main' }"
                    class="navigation__parent-link"
                >
                    Назад
                </router-link>
            </span>
        </div>

        <div class="development__content mt-3">
            <h2 class="development__title">
                {{ news.title }}
            </h2>

            <div class="d-flex flex-column flex-lg-row mt-4">
                <div class="col-12 col-lg-4 development__image-content">
                    <div class="d-flex justify-content-center">
                        <img
                            :src="news.image"
                            alt=""
                            class="development__image rounded"
                        >
                    </div>

                    <!-- <div class="d-flex justify-content-center mt-3">
                        <img
                            class="d-inline-block"
                            src="../../assets/views/development/label.png"
                            alt=""
                        >
                    </div> -->
                </div>

                <div
                    class="col-12 col-lg-7 pe-lg-1 pe-xl-3 pe-xxl-5 mt-5 mt-lg-0"
                    v-html="news.description || news.short_description"
                />
            </div>
        </div>
    </section>
</template>

<script>
import routerMixin from "@/mixins/routerMixin";
import {onMounted, computed} from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router'

export default {
    name: "NewsDetail",

    setup() {
        const { goToPage } = routerMixin();
        const store = useStore()
        const route = useRoute()

        onMounted(() => {
            document.body.scrollIntoView({ behavior: "smooth" });
        })

        const { id } = route.params

        Promise.allSettled([
            store.dispatch('news/getNewById', id)
        ])

        const news = computed(() => store.getters['news/selectedNew'])

        return {
            goToPage,
            news
        }
    }
}
</script>
